import React from 'react'
import { Spinner } from 'components'
import { css } from 'emotion'

const UserLoadingView = () => (
  <div className={styles.loading}>
    <Spinner color="white" />
  </div>
)

const styles = {
  loading: css({
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

export default UserLoadingView
